import { makeAutoObservable, runInAction, onBecomeObserved } from 'mobx'
import { RootStore } from './rootStore'
import { PricingService } from '../services/pricingService'
import { Adtech, CompanyIntegrationDto, PriceDto } from '../types/Pricing'
import { ToastType } from '../types/ToastData'
import { Company } from '../types/Company'

export class PricingStore {
    private pricingService: PricingService
    private rootStore: RootStore

    error: { message: string } = null
    integrations: CompanyIntegrationDto[] = []
    prices: PriceDto[] = []

    constructor(rootStore: RootStore, pricingService: PricingService) {
        this.pricingService = pricingService
        this.rootStore = rootStore

        makeAutoObservable(this)

        onBecomeObserved(this, 'prices', async () => {
            const res = await this.pricingService.getAdtechPrices()
            this.rootStore.appStore.pendingRequests++
            runInAction(() => {
                this.prices = res ? res : []
            })
            this.rootStore.appStore.pendingRequests--
        })
    }

    trimPriceName(name: string) {
        return name.split(' ')[name.split(' ').length - 1]
    }

    async getCompaniesBySeatId(seatId: string, type: Adtech) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()
            let companies: string[] = []

            companies = await this.pricingService.getCompaniesByIntegration(type, seatId)

            return companies || ([] as string[])
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to get companies by ${type} seat id`
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyIntegration(company: Company, adtech: Adtech) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            const res = await this.pricingService.getCompanyIntegration(adtech, company.id)

            const handleIntegration = () => {
                const differentCompanyExists = this.integrations.length > 0 && this.integrations[0].companyID !== company.id

                if (differentCompanyExists) {
                    return [res]
                } else {
                    const existingIntegrationIndex = this.integrations.findIndex(
                        (integration) => integration.companyID === res.companyID && integration.adTechName === adtech
                    )

                    if (existingIntegrationIndex !== -1) {
                        return this.integrations.map((integration, index) => (index === existingIntegrationIndex ? res : integration))
                    } else {
                        return [...this.integrations, res]
                    }
                }
            }

            runInAction(() => {
                this.integrations = res ? handleIntegration() : this.integrations
            })
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to get company ${adtech} integration`
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async updateCompanyIntegration(company: Company, integration: CompanyIntegrationDto) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            await this.pricingService.updateCompanyIntegration(integration.adTechName, company.id, integration)

            runInAction(() => {
                const index = this.integrations.findIndex((x) => x?.adTechName === integration.adTechName)
                this.integrations[index] = integration
            })
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to update company ${integration.adTechName} integration`
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Success',
                    body: `Company ${integration.adTechName} integration updated`
                })
            }
        }
    }

    async createCompanyIntegration(company: Company, integration: CompanyIntegrationDto) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            await this.pricingService.createCompanyIntegration(integration.adTechName, company.id, integration)
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to create company ${integration.adTechName} integration`
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.integrations = [...this.integrations, integration]
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Success',
                    body: `Company ${integration.adTechName} integration created`
                })
            }
        }
    }
}
