export interface PriceDto {
    id: number
    name: string
    price: number
    external_price: number
    defaultTargeting: boolean
    defaultRetargeting: boolean
    currency: PriceCurrency
    ad_tech_config: {
        xandr_curate: {
            external_id: number
        }
        xandr_monetize: {
            external_id: number
        }
    }
}

export interface SegmentPrice {
    adTechName: Adtech
    price: number
    currency: PriceCurrency
}

export interface CompanyIntegrationDto {
    companyID: number
    adTechName: Adtech
    targetingPriceID: number
    retargetingPriceID: number
    seatID: string
    config?: {
        gam?: {
            ppsEnabled?: boolean
        }
    }
}

export interface CompanyIntegration {
    adTechName: Adtech
    seatID: string
    config?: {
        gam?: {
            ppsEnabled?: boolean
        }
    }
}

export enum PriceCurrency {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP'
}

export enum PriceSender {
    Xandr = 'xandr',
    Pubmatic = 'pubmatic',
    GAM = 'gam',
    Equativ = 'equativ',
    Criteo = 'criteo'
}

export enum Adtech {
    XandrCurate = 'xandr_curate',
    XandrMonetize = 'xandr_monetize',
    GAM = 'gam',
    Pubmatic = 'pubmatic',
    Equativ = 'equativ',
    Criteo = 'criteo',
    Onetag = 'onetag'
}

export enum PriceReceiver {
    Company = 'company',
    Segment = 'segment'
}

export enum PriceAction {
    Delete = 'delete',
    Update = 'update',
    Add = 'add'
}
