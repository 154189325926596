export const site = process.env.SITE
export const baseApiUrl = resolveBaseApiUrl()

function resolveBaseApiUrl() {
    switch (process.env.BRANCH) {
        case 'demo':
            return 'https://demo-customer-service-2gsq4c2u6a-ew.a.run.app'
        case 'dev':
            return 'https://customer-service-lwvpr3pb6a-ew.a.run.app'
        default:
            return 'https://customer-service.anonymised.io'
    }
}

//export const baseApiUrl = process.env.NODE_ENV === 'production' ? 'https://customer-service.id-ward.com' : 'https://demo-customer-service-2gsq4c2u6a-ew.a.run.app';
//export const branch = process.env.BRANCH;
