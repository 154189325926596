export enum AdPlatforms {
    Curate = 'Curate',
    Monetize = 'Monetize',
    Equativ = 'Equitiv',
    GAM = 'GAM',
    Criteo = 'Criteo',
    Pubmatic = 'Pubmatic',
}

export enum AdStatus {
    Inactive = 'not_active',
    Active = 'active',
}

export interface AdSegment {
    status: AdStatus
}