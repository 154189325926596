import { LocalStorageKeys } from '../types/LocalStorage'
import { SessionStorageKeys } from '../types/SessionStorage'
import { HttpStatusCode } from './types'

export abstract class ApiService {
    protected baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    protected authHeader() {
        const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AuthenticationCode))

        if (auth && auth.token) {
            return { 'Authorization': 'Bearer ' + auth.token }
        }
        throw new Error('unauthorized')
    }

    //we extract the body in that way because the BE returns not valid JSON for the DELETE requests,
    //TODO: BE invalid json response fix
    protected async getBody(response: Response) {
        const contentType = response.headers.get('content-type')
        
        if (contentType && contentType.includes('application/json')) {
            return await response.json()
        } 

        return { message: await response.text() }        
    }

    protected async handleResponse(response: Response) {
        const data = await this.getBody(response)

        if (!response.ok) {
            if (response.status === HttpStatusCode.Unauthorized && window.location.pathname !== '/login') {
                window.location.href = '/login'
                localStorage.removeItem(LocalStorageKeys.AuthenticationCode)
                localStorage.setItem(LocalStorageKeys.LogoutEvent, 'logout-event' + Math.random())
                sessionStorage.setItem(SessionStorageKeys.Message, 'Unauthorized access. Please login again.')
                return Promise.reject({ message: 'Unauthorized access. Please login again.' })
            }
            const error = (data && {status: response.status, message: data.message, attemptsLeft: data.attemptsLeft, cooldown: data.cooldown, details: data.details }) || response.statusText
            return Promise.reject(error)
        }
        sessionStorage.removeItem(SessionStorageKeys.Message)

        return data
    }

    protected async get(url: string, headers?: any, differentBaseUrl?: boolean) {
        const header = this.authHeader()

        const requestOptions = {
            method: 'GET',
            headers: { ...header, ...headers }
        }
        const response = differentBaseUrl ? await fetch(`${url}`, requestOptions) : await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async post(url: string, dto?: any, differentBaseUrl?: boolean, isIncognito = false) {
        const header = isIncognito ? {} : this.authHeader()

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = differentBaseUrl ? await fetch(`${url}`, requestOptions) : await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async put(url: string, dto?: any) {
        const header = this.authHeader()
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async patch(url: string, dto?: any) {
        const header = this.authHeader()

        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async del(url: string): Promise<void> {
        const header = this.authHeader()

        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', ...header }
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }
}