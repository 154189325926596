import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { CompanyInsights, InsightsFilters, MaintenanceMode } from '../types/GraphData'
export class InsightsService extends ApiService {
    async filterInsightsDev(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-audience-estimation-364873551811.europe-west1.run.app/estimate', filters, true)
    }

    async getSnapshotDev(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-audience-estimation-364873551811.europe-west1.run.app/static_data', filters, true)
    }

    async filterInsightsClickHouse(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/estimate', filters, true)
    }

    async getSnapshotClickHouse(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/static_data', filters, true)
    }

    async filterInsightsProd(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/estimate', filters, true)
    }

    async getSnapshotProd(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/static_data', filters, true)
    }

    async checkMaintenanceModeDev(): Promise<MaintenanceMode> {
        return this.get('https://clickhouse-audience-estimation-364873551811.europe-west1.run.app/maintenance', null, true)
    }

    async checkMaintenanceModeProd(): Promise<MaintenanceMode> {
        return this.get('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/maintenance', null, true)
    }

    async getDirectLookalikeCategoriesDev(categories: string[]): Promise<string[]> {
        return this.post(
            `https://clickhouse-audience-estimation-364873551811.europe-west1.run.app/get_lookalike_cats`,
            { category: categories },
            true
        )
    }

    async getDirectLookalikeCategoriesProd(categories: string[]): Promise<string[]> {
        return this.post(`https://hll-audience-estimation-2gsq4c2u6a-ew.a.run.app/get_lookalike_cats`, { category: categories }, true)
    }
}

export const insightsService = new InsightsService(baseApiUrl)
