import { createContext, useContext } from 'react'
import { segmentService } from '../services/segmentService'
import { authService } from '../services/authService'
import { companyService } from '../services/companyService'
import { AuthStore } from './authStore'
import { SegmentStore } from './segmentStore'
import { CompanyStore } from './companyStore'
import { AppStore } from './appStore'
import { AdminStore } from './adminStore'
import { adminService } from '../services/adminService'
import { InsightsStore } from './insightsStore'
import { insightsService } from '../services/insightsService'
import { PricingStore } from './pricingStore'
import { pricingService } from '../services/pricingService'
import { LocalStore } from './localStore'

export class RootStore {
    authStore: AuthStore
    insightsStore: InsightsStore
    segmentStore: SegmentStore
    appStore: AppStore
    companyStore: CompanyStore
    adminStore: AdminStore
    pricingStore: PricingStore
    localStore: LocalStore

    constructor() {
        this.adminStore = new AdminStore(this, adminService)
        this.authStore = new AuthStore(this, authService)
        this.insightsStore = new InsightsStore(this, insightsService)
        this.segmentStore = new SegmentStore(this, segmentService)
        this.companyStore = new CompanyStore(this, companyService)
        this.pricingStore = new PricingStore(this, pricingService)
        this.appStore = new AppStore()
        this.localStore = new LocalStore(this)
    }
}

export const RootContext = createContext<RootStore>(null)

export const useRootStore = (): RootStore => useContext(RootContext)