export enum SegmentCreationFields {
    Name = 'name',
    Frequency = 'frequency',
    FrequencyCount = 'frequencyCount',
    Type = 'type',
    DateSlidingDays = 'dateSlidingDays',
    Dates = 'dates'
}

//TODO: find a better name for this file
export enum EventTypeOptions {
    Pageview = 'pageviewCount',
    Clicks = 'clickCount'
}

export type SegmentEvent = {
    type: EventTypeOptions
    frequency: Operator
    frequencyCount: string
}

export type LogicalOperator = 'AND' | 'OR'
export type Operator = 'IN' | 'NOT' | 'LT' | 'GT' | 'LTE' | 'GTE'

export enum DateField {
    Date = 'date',
    DateSlidingDays = 'dateSlidingDays'
}

export enum NodeField {
    Language = 'language',
    InterestCategory = 'interestCategory',
    InterestKeyword = 'interestKeyword',
    Country = 'country',
    City = 'city',
    DeviceOs = 'deviceOs',
    DeviceBrowser = 'deviceBrowser',
    DeviceType = 'deviceType',
    Topic = 'topic'
}

export enum TargetingField {
    Category = 'category',
    City = 'city',
    Country = 'country',
    DeviceBrowser = 'deviceBrowser',
    DeviceOs = 'deviceOs',
    DeviceType = 'deviceType',
    Keyword = 'keyword',
    Language = 'language',
    SizeRecency = 'sizeRecency',
    Topic = 'topicIds',
    LookalikeCats = 'lookalikeCats',
    Gender = 'gender',
    Networth = 'networth'
}

export type Node = {
    field?: TargetingField
    options: string[] | number[]
    oldField?: TargetingField
}
export type Group = {
    operator: LogicalOperator
    nodeList: Array<Group | Node>
    id?: string
    strList?: string[]
    field?: NodeField | EventTypeOptions | DateField
}

export enum DeviceOsOptions {
    Android = 'Android',
    Ios = 'iOS',
    Windows = 'Windows',
    MacOs = 'macOS',
    Linux = 'Linux',
    Chrome = 'ChromeOS',
    Others = 'Others'
}

export enum DeviceBrowserOptions {
    Chrome = 'Chrome',
    Edge = 'Edge',
    Firefox = 'Firefox',
    Safari = 'Safari',
    Opera = 'Opera',
    Samsung = 'Samsung',
    Others = 'Others'
}

export enum DeviceTypeOptions {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop'
}

export enum GenderOptions {
    Male = 'M',
    Female = 'F',
    Undefined = 'X'
}

export enum NetworthOptions {
    Normal = 'normal',
    High = 'high'
}
