import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { Company, CompanyData } from '../types/Company'
import { CompanyReport } from '../types/CompanyReport'
import { BaseDomain, Domain } from '../types/Domain'
import { DomainStatus } from '../types'

export class CompanyService extends ApiService {
    async getTaxTypeList() {
        return Promise.resolve([
            { value: 'eu_vat', label: 'European VAT' },
            { value: 'gb_vat', label: 'United Kingdom VAT' },
            { value: 'us_ein', label: 'United States EIN' }
        ])
    }

    async getUserCompany(companyId: number): Promise<CompanyData | null> {
        return this.get(`/api/v3/company/${companyId}`)
    }

    async getCompanyAdmin(companyId: number): Promise<Company | null> {
        return this.get(`/api/admin/v3/company/${companyId}`)
    }

    async getCompanyReports(id: number): Promise<CompanyReport[] | null> {
        return this.get(`/api/v2/company/${id}/report`)
    }

    async getCompanies(): Promise<Company[]> {
        return this.get('/api/v2/company')
    }

    async getCompanyDomains(id: number): Promise<Domain[]> {
        return this.get(`/api/v2/company/${id}/domain`)
    }

    async deleteCompanyDomain(id: number, domainId: number): Promise<void> {
        return this.del(`/api/v2/company/${id}/domain/${domainId}`)
    }

    async create(company: Company): Promise<Company> {
        return this.post('/api/admin/v2/company', company)
    }

    async createDomains(id: number, addresses: BaseDomain[]): Promise<Domain[]> {
        return this.post(`/api/v2/company/${id}/domain`, addresses)
    }

    async updateDomainStatus(companyId: number, domainId: number, status: DomainStatus): Promise<Domain> {
        return this.put(`/api/v2/company/${companyId}/domain/${domainId}/${status}`)
    }

    async update(company: Partial<Company>, id: number): Promise<Company> {
        return this.patch(`/api/v2/company/${id}`, company)
    }

    async updateAdmin(company: Partial<Company>, id: number): Promise<Company> {
        return this.patch(`/api/admin/v2/company/${id}`, company)
    }

    async filterInsights(filters: any): Promise<Company> {
        return this.post('https://clickhouse-estimation-2gsq4c2u6a-ew.a.run.app/estimate', filters, true)
    }
}

export const companyService = new CompanyService(baseApiUrl)
