import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx'
import { RootStore } from './rootStore'
import { AdminSegmentDto } from '../types/Segment'
import { Company } from '../types/Company'

export class LocalStore {
    private rootStore: RootStore

    error: string = null
    segments: AdminSegmentDto[]
    adminCompany: Company
    hidden: string[]

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.hidden = []

        makeAutoObservable(this)

        onBecomeObserved(this, 'hidden', async () => {
            if (this.hidden.length > 0) return
            const hide = localStorage.getItem('hide')
            const res = hide ? JSON.parse(hide) : []
            runInAction(() => {
                this.hidden = res
            })
        })
    }

    setHidden(hidden: string[]) {
        localStorage.setItem('hide', JSON.stringify(hidden))
        this.hidden = hidden
    }

    getHidden() {
        return this.hidden
    }

    isHidden(value: string) {
        return this.hidden.includes(value)
    }
}
