
export enum ToastType {
    Error = 'text-danger',
    Success = 'text-secondary',
    Activation = 'text-success',
    Warning = 'text-warning'
}

export type ToastData = {
    id?: string,
    type: ToastType,
    title: string,
    body?: string | JSX.Element,
    isHidden?: boolean
    time?: number
}
