import { ApiService } from './apiService'
import { baseApiUrl, site } from '../config'
import { SiteType } from '../types'
import { Adtech, CompanyIntegrationDto, PriceDto } from '../types/Pricing'

export class PricingService extends ApiService {
    protected isPublisherWebSite: boolean

    constructor(baseUrl: string, siteType: SiteType) {
        super(baseUrl)
        this.isPublisherWebSite = siteType === SiteType.Publisher
    }

    async getCompanyIntegration(adtech: Adtech, id: number): Promise<CompanyIntegrationDto | null> {
        return this.get(`/api/admin/v3/${adtech}/company/${id}`)
    }

    async getAdtechPrices(): Promise<PriceDto[]> {
        return this.get(`/api/admin/v3/price`)
    }

    async getCompaniesByIntegration(adtech: Adtech, id: string): Promise<string[]> {
        return this.get(`/api/admin/v3/${adtech}/seat/${id}`)
    }

    async updateCompanyIntegration(adtech: Adtech, id: number, data: CompanyIntegrationDto): Promise<CompanyIntegrationDto> {
        return this.put(`/api/admin/v3/${adtech}/company/${id}`, data)
    }

    async createCompanyIntegration(adtech: Adtech, id: number, data: CompanyIntegrationDto): Promise<CompanyIntegrationDto> {
        return this.post(`/api/admin/v3/${adtech}/company/${id}`, data)
    }
}

export const pricingService = new PricingService(baseApiUrl, site as SiteType)
