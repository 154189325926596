import { Company } from '../types/Company'

import { CSSObjectWithLabel } from 'react-select'
import { DeviceBrowserOptions, DeviceOsOptions, DeviceTypeOptions, GenderOptions, NetworthOptions } from '../types/AudienceBuilder'

export const UNSAVED_SEGMENT_ID = 'undefined'

export enum TimeUnit {
    Week = 'week',
    Day = 'day',
    Hour = 'hour',
    Minute = 'minute',
    Second = 'second'
}

export const CAPPING_TIME_UNITS = [
    { label: 'weeks', value: TimeUnit.Week },
    { label: 'days', value: TimeUnit.Day },
    { label: 'hours', value: TimeUnit.Hour },
    { label: 'minutes', value: TimeUnit.Minute },
    { label: 'seconds', value: TimeUnit.Second }
]

export const CUSTOMER_DEMO_COMPANY_ID = 2234

export const WEBSITE_REGEXP = /^\b(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\b$/

export const TAX_REGEXP = {
    us_ein: [/^(\d{2}-\d{7})$/],
    eu_vat: [
        /^(AT)?U[0-9]{8}$/,
        /^(BE)?0[0-9]{9}$/,
        /^(BG)?[0-9]{9,10}$/,
        /^(CY)?[0-9]{8}L$/,
        /^(CZ)?[0-9]{8,10}$/,
        /^(DE)?[0-9]{9}$/,
        /^(DK)?[0-9]{8}$/,
        /^(EE)?[0-9]{9}$/,
        /^(EL|GR)?[0-9]{9}$/,
        /^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/,
        /^(SK)?[0-9]{10}$/,
        /^(SI)?[0-9]{8}$/,
        /^(SE)?[0-9]{12}$/,
        /^(RO)?[0-9]{2,10}$/,
        /^(PT)?[0-9]{9}$/,
        /^(PL)?[0-9]{10}$/,
        /^(NL)?[0-9]{9}B[0-9]{2}$/,
        /^(MT)?[0-9]{8}$/,
        /^(LV)?[0-9]{11}$/,
        /^(LU)?[0-9]{8}$/,
        /^(LT)?([0-9]{9}|[0-9]{12})$/,
        /^(IT)?[0-9]{11}$/,
        /^(IE)?[0-9]S[0-9]{5}L$/,
        /^(HU)?[0-9]{8}$/,
        /^(FR)?[0-9A-Z]{2}[0-9]{9}$/,
        /^(FI)?[0-9]{8}$/,
        /^(UA\d{10})$/
    ],
    gb_vat: [/^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/]
}

export const EMAIL_REGEXP =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const DEFAULT_COMPANY: Company = {
    address: '',
    addressPostalCode: '',
    addressBuilding: '',
    addressStreet: '',
    addressCity: '',
    addressRegion: '',
    addressCountry: '',
    billingEmail: '',
    technicalEmail: '',
    isAdvertiser: false,
    isPublisher: false,
    equativRequest: false,
    id: 0,
    number: 0,
    name: '',
    vatNumber: '',
    vatType: 'eu_vat',
    equativId: '',
    pubmaticId: '',
    criteoId: 0,
    gamId: '',
    isAdManager: false,
    websiteUrl: '',
    currency: '',
    emailNotification: false,
    evergreenSegments: false,
    customTaxonomy: false,
    retargeting: false,
    sendPublicSegment: false
}

export const SELECT_Z_INDEX_STYLES = {
    menuPortal: (base: CSSObjectWithLabel) => ({
        ...base,
        zIndex: 9999
    })
}

export const GENDER_OPTIONS = [
    { label: 'female', value: GenderOptions.Female },
    { label: 'male', value: GenderOptions.Male },
    { label: 'undefined', value: GenderOptions.Undefined }
]

export const OS_OPTIONS = [
    { label: DeviceOsOptions.Android, value: DeviceOsOptions.Android },
    { label: DeviceOsOptions.Ios, value: DeviceOsOptions.Ios },
    { label: DeviceOsOptions.Windows, value: DeviceOsOptions.Windows },
    { label: DeviceOsOptions.MacOs, value: DeviceOsOptions.MacOs },
    { label: DeviceOsOptions.Linux, value: DeviceOsOptions.Linux },
    { label: DeviceOsOptions.Chrome, value: DeviceOsOptions.Chrome }
    // { label: DeviceOsOptions.Others, value: DeviceOsOptions.Others }
]

export const DEVICE_OPTIONS = [
    { label: DeviceBrowserOptions.Chrome, value: DeviceBrowserOptions.Chrome },
    { label: DeviceBrowserOptions.Edge, value: DeviceBrowserOptions.Edge },
    { label: DeviceBrowserOptions.Firefox, value: DeviceBrowserOptions.Firefox },
    { label: DeviceBrowserOptions.Safari, value: DeviceBrowserOptions.Safari },
    { label: DeviceBrowserOptions.Opera, value: DeviceBrowserOptions.Opera },
    { label: DeviceBrowserOptions.Samsung, value: DeviceBrowserOptions.Samsung }
    // { label: DeviceBrowserOptions.Others, value: DeviceBrowserOptions.Others }
]

export const PLATFORM_OPTIONS = [
    { label: DeviceTypeOptions.Mobile, value: DeviceTypeOptions.Mobile },
    { label: DeviceTypeOptions.Tablet, value: DeviceTypeOptions.Tablet },
    { label: DeviceTypeOptions.Desktop, value: DeviceTypeOptions.Desktop }
]

export const NETWORTH_OPTIONS = [{ label: 'high', value: NetworthOptions.High }]
