export function handleInterestScore(value: number): string {
    switch (value) {
        case 0:
            return 'Moderate'
        case 0.15:
            return 'Good'
        case 0.35:
            return 'High'
        case 0.9:
            return 'Very High'
        case 2:
            return 'Highest'
        default:
            return 'Moderate'
    }
}
